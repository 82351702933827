import { Controller } from "@hotwired/stimulus";
import SignaturePad from "signature_pad";

// Connects to data-controller="pdf-form-signer"
export default class extends Controller {
  static targets = [
    "signaturePadCanvas",
    "saveSignatureButton",
    "submitButton",
    "field",
  ];

  connect() {
    this.currentFieldIndex = 0;
    this.initializeSignaturePad();

    this.fieldTargets.forEach((field) => {
      if (field.value) {
        return;
      }

      if (field.type == "date") {
        field.valueAsDate = new Date();
      }

      if (field.type == "time") {
        const date = new Date();

        field.value = `${date.getHours()}:${date.getMinutes()}`;
      }
    });
  }

  initializeSignaturePad() {
    this.signaturePad = new SignaturePad(this.signaturePadCanvasTarget, {
      backgroundColor: "rgba(0, 0, 0, 0)",
    });

    this.resizeSignaturePad();
  }

  resizeSignaturePad() {
    if (this.signaturePad) {
      this.signaturePad.clear();
      let ratio = Math.max(window.devicePixelRatio || 1, 1);

      this.signaturePadCanvasTarget.width =
        this.signaturePadCanvasTarget.offsetWidth * ratio;
      this.signaturePadCanvasTarget.height =
        this.signaturePadCanvasTarget.offsetHeight * ratio;
      this.signaturePadCanvasTarget.getContext("2d").scale(ratio, ratio);
    }
  }

  inputClicked(e) {
    if (e.currentTarget && e.currentTarget.dataset.fieldType == "signature") {
      // Set the currentFieldIndex to the selected field
      const field = e.currentTarget.querySelector("input[type=hidden]");
      if (field) {
        this.currentFieldIndex = this.fieldTargets.indexOf(field);

        this.#showSignatureField();
        this.resizeSignaturePad();
      }
    }
  }

  saveSignature(e) {
    e.preventDefault();

    // Set the input to the signature pad value
    const field = this.fieldTargets[this.currentFieldIndex];
    if (field) {
      field.value = this.signaturePad.toDataURL("image/png");

      // Check if an img element exists and update the signature.
      // If it doesn't exist, create one
      let imgElement = field.closest("div.field-preview").querySelector("img");

      if (imgElement) {
        imgElement.src = this.signaturePad.toDataURL("image/png");
      } else {
        imgElement = document.createElement("img");
        imgElement.classList.add("signature-preview");
        imgElement.src = this.signaturePad.toDataURL("img/png");

        field.closest("div.field-preview").appendChild(imgElement);
      }
    }

    this.#hideSignatureField();
  }

  #showSignatureField() {
    this.signaturePadCanvasTarget.classList.remove("d-none");
    this.saveSignatureButtonTarget.classList.remove("d-none");
    this.submitButtonTarget.classList.add("d-none");
  }

  #hideSignatureField() {
    this.signaturePadCanvasTarget.classList.add("d-none");
    this.saveSignatureButtonTarget.classList.add("d-none");
    this.submitButtonTarget.classList.remove("d-none");
  }

  setValue(field) {
    const currentInput = this.getCurrentInput();

    // Set the value appropriately
    const hiddenInput = field.querySelector("input");
    const userFacingValue = field.querySelector("span");

    if (
      field.dataset.fieldType == "push_button" ||
      field.dataset.fieldType == "signature"
    ) {
      hiddenInput.value = this.signaturePad.toDataURL("image/png");

      if (userFacingValue.querySelector("img")) {
        userFacingValue.querySelector("img").remove();
      }

      const imageElement = document.createElement("img");
      imageElement.src = this.signaturePad.toDataURL("image/png");

      userFacingValue.appendChild(imageElement);
    } else if (field.dataset.fieldType == "checkbox") {
      if (currentInput.value == "1") {
        userFacingValue.innerHTML = "&check;";
      }

      hiddenInput.value = currentInput.value;
    } else if (field.dataset.fieldType == "date") {
      const [year, month, day] = currentInput.value.split("-");

      userFacingValue.innerText = `${month}/${day}/${year}`;
      hiddenInput.value = currentInput.value;
    } else if (field.dataset.fieldType == "time") {
      const [hours, minutes] = currentInput.value.split(":");
      let hour = parseInt(hours, 10);

      const period = hour >= 12 ? "PM" : "AM";

      if (hour === 0) {
        hour = 12;
      } else if (hour > 12) {
        hour = hour - 12;
      }

      const formattedHour = String(hour).padStart(2, "0");
      userFacingValue.innerText = `${formattedHour}:${minutes} ${period}`;
      hiddenInput.value = currentInput.value;
    } else {
      userFacingValue.innerText = currentInput.value;
      hiddenInput.value = currentInput.value;
    }
  }
}
