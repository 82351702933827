import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="settings--pdf-form-template-field-default-value"
export default class extends Controller {
  static targets = [
    "defaultValueTextInput",
    "defaultValueInputContainer",
    "fieldTypeInput",
  ];

  connect() {
    this.textInput = this.defaultValueTextInputTarget;
    this.inputName = this.defaultValueTextInputTarget.name;
    this.inputValue = this.defaultValueTextInputTarget.value;
    this.fieldType = this.fieldTypeInputTarget.value;

    this.#changeInput(this.fieldType);
  }

  fieldTypeChanged(event) {
    const value = event.currentTarget.value;
    this.#changeInput(value);
  }

  #changeInput(type) {
    if (type == "checkbox") {
      this.#createSelectInput();
    } else {
      this.#createTextInput();
    }
  }

  #createSelectInput() {
    this.defaultValueInputContainerTarget.innerHTML = "";

    const selectInput = document.createElement("select");
    selectInput.classList.add("form-select");
    selectInput.name = this.inputName;

    const blankOption = document.createElement("option");
    blankOption.innerText = "";
    selectInput.appendChild(blankOption);

    const checkedValue = document.createElement("option");
    checkedValue.innerText = "Checked";
    checkedValue.value = "true";
    selectInput.appendChild(checkedValue);

    const notCheckedValue = document.createElement("option");
    notCheckedValue.innerText = "Unchecked";
    notCheckedValue.value = "false";
    selectInput.appendChild(notCheckedValue);

    selectInput.value = this.inputValue;
    this.defaultValueInputContainerTarget.appendChild(selectInput);
  }

  #createTextInput() {
    this.defaultValueInputContainerTarget.innerHTML = "";

    const textInput = document.createElement("input");
    textInput.classList.add("form-control");
    textInput.name = this.inputName;
    textInput.value = this.inputValue;

    this.defaultValueInputContainerTarget.appendChild(textInput);
  }
}
